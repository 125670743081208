
import { defineComponent } from 'vue'
import { FmtUtils } from '@/services'

export default defineComponent({
  name: 'FormInputPhoneNumberComponent',
  props: {
    id: String,
    placeholder: String,
    modelValue: String,
    readonly: Boolean,
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      model: FmtUtils.fmtPhoneNumber(this.modelValue),
    }
  },
  watch: {
    modelValue() {
      this.model = FmtUtils.fmtPhoneNumber(this.modelValue)
    },
    model() {
      let value = this.model ?? ''
      value = value.replace(/[^0-9]/g, '')
      if (value.length > 10) {
        value = value.substring(0, 10)
      }
      this.$emit('update:modelValue', value)
      this.model = FmtUtils.fmtPhoneNumber(value)
    },
  },
  computed: {
    inputClass() {
      if (this.readonly) {
        return 'form-control-plaintext'
      } else {
        return 'form-control'
      }
    },
  },
})
