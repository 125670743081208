
import ErrorMessages from '@/components/Messages.vue'
import FormInputDate from '@/components/FormInputDate.vue'
import FormInputPersonalIdentityNumber from '@/components/FormInputPersonalIdentityNumber.vue'
import FormInputPhoneNumber from '@/components/FormInputPhoneNumber.vue'
import FormInputPostalCode from '@/components/FormInputPostalCode.vue'
import { CampaignService, EmployeeService, ICreateEmployeeFromNewEmployeePayload, PasswordService } from '@/services'
import { Campaign, EmployeeRelativeRelation, EmploymentForm, NewEmployee } from '@/types'
import { Options, Vue } from 'vue-class-component'
import { FmtUtils } from '@/services'

interface IFormData {
  companyEmailUsername?: string
  campaignEmailUsername?: string
  password?: string
}

@Options({
  name: 'AddNewEmployeeAccountsView',
  components: {
    ErrorMessages,
    FormInputDate,
    FormInputPersonalIdentityNumber,
    FormInputPhoneNumber,
    FormInputPostalCode,
  },
})
export default class AddNewEmployeeAccountsView extends Vue {
  loadingNewEmployees = false
  newEmployees: NewEmployee[] = []

  employee: NewEmployee | null = null

  loadingCampaigns = false
  campaigns: Campaign[] = []

  loadingEmploymentForms = false
  employmentForms: EmploymentForm[] = []

  loadingRelationTypes = false
  relationTypes: EmployeeRelativeRelation[] = []

  formData: IFormData = {}
  checkingCompanyEmail = false
  checkingCampaignEmail = false

  savingEmployee = false
  creatingAccounts = false
  settingEmployeePhoneExtension = false
  notifyingHR = false
  notifyingManager = false

  fmt = FmtUtils

  sortBy = 'firstName'
  sortReverse = false

  mounted(): void {
    this.fetchCampaigns().then((campaigns: Campaign[]) => {
      this.campaigns = campaigns
    })

    this.fetchEmploymentForms().then((employmentForms: EmploymentForm[]) => {
      this.employmentForms = employmentForms
    })

    this.fetchNewEmployees().then((employees: NewEmployee[]) => {
      this.newEmployees = employees
    })

    this.fetchRelationTypes().then((relationTypes: EmployeeRelativeRelation[]) => {
      this.relationTypes = relationTypes
    })
  }

  beforeUpdate(): void {
    if (!this.employee) {
      const newEmployeeId = parseInt(this.$route.params.newEmployeeId as string)
      this.employee = this.newEmployees.find((e) => e.id === newEmployeeId) ?? null

      if (this.employee) {
        this.generateEmailUsername(this.employee)
      }
    }
  }

  async refresh(): Promise<void> {
    this.newEmployees = []
    this.employee = null
    this.loadingNewEmployees = false

    this.newEmployees = await this.fetchNewEmployees()
  }

  selectNewEmployee(newEmployee: NewEmployee): void {
    this.$router.replace({ name: 'AddNewEmployeeAccounts', params: { newEmployeeId: newEmployee.id } })

    this.employee = newEmployee

    if (newEmployee.password) {
      this.formData.password = newEmployee.password
    } else {
      this.formData.password = undefined
    }

    this.generateEmailUsername(newEmployee)

    if (this.$refs.createAccountsErrorHandler as ErrorMessages) {
      ;(this.$refs.createAccountsErrorHandler as ErrorMessages).clear()
    }

    this.creatingAccounts = false
    this.savingEmployee = false
    this.settingEmployeePhoneExtension = false

    var el = this.$refs.formEmail as HTMLInputElement
    if (el && el.classList) {
      el.classList.remove('is-valid')
      el.classList.remove('is-invalid')
    }
    el = this.$refs.formCampaignEmail as HTMLInputElement
    if (el && el.classList) {
      el.classList.remove('is-valid')
      el.classList.remove('is-invalid')
    }
  }

  async fetchNewEmployees(): Promise<NewEmployee[]> {
    try {
      this.loadingNewEmployees = true
      return await EmployeeService.fetchNewEmployees(true, true)
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingNewEmployees = false
    }
  }

  async fetchEmploymentForms(): Promise<EmploymentForm[]> {
    try {
      this.loadingEmploymentForms = true
      return await EmployeeService.fetchEmploymentForms()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingEmploymentForms = false
    }
  }

  async fetchCampaigns(): Promise<Campaign[]> {
    try {
      this.loadingCampaigns = true
      return await CampaignService.fetchCampaigns(true, true)
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingCampaigns = false
    }
  }

  async fetchRelationTypes(): Promise<EmployeeRelativeRelation[]> {
    try {
      this.loadingRelationTypes = true
      return await EmployeeService.fetchEmployeeRelativeRelations()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingRelationTypes = false
    }
  }

  async createAccounts(e: NewEmployee): Promise<void> {
    const msgs = this.$refs.createAccountsErrorHandler as ErrorMessages
    msgs.clear()

    if (!this.employee) {
      return
    }

    if (!this.formData.password) {
      msgs.pushErr('Generera lösenord först')
      return
    }

    try {
      this.creatingAccounts = true

      const payload: ICreateEmployeeFromNewEmployeePayload = {
        newEmployeeId: e.id,
        password: this.formData.password,
        companyEmailAddress: this.companyEmailAddress,
      }

      if (!e.campaign.isAdministration) {
        payload.campaignEmailAddress = this.campaignEmailAddress
      }

      const employee = await EmployeeService.createEmployeeFromNewEmployee(payload)
      this.employee.employeeNumber = employee.employeeNumber
      msgs.pushInfo('Användare skapad')
    } catch (err) {
      console.log('Error creating new emplyoee:', err)
      msgs.pushErr(err)
    } finally {
      this.creatingAccounts = false
    }
  }

  async checkEmailAvailable(emailAddress: string | undefined, elem: unknown, emailType: string): Promise<void> {
    if (!emailAddress) {
      return
    }

    const el = elem as HTMLInputElement
    el.classList.remove('is-valid')
    el.classList.remove('is-invalid')

    try {
      emailType === 'company' ? (this.checkingCompanyEmail = true) : (this.checkingCampaignEmail = true)

      const availability = await EmployeeService.isEmailAddressAvailable(emailAddress)

      if (availability.isAvailable) {
        el.classList.add('is-valid')
      } else {
        el.classList.add('is-invalid')
      }
    } catch (err: unknown) {
      el.classList.add('is-invalid')
      ;(this.$refs.emailValidationErrorHandler as ErrorMessages).pushErr(err)
    } finally {
      emailType === 'company' ? (this.checkingCompanyEmail = false) : (this.checkingCampaignEmail = false)
    }
  }

  generateEmployeePassword(): void {
    this.formData.password = PasswordService.generatePassword()
  }

  generateEmailUsername(e: NewEmployee): void {
    if (e.companyEmailAddress) {
      const username = e.companyEmailAddress.match(/^([^@]*)@/)
      this.formData.companyEmailUsername = username ? username[1] : undefined
    } else {
      this.formData.companyEmailUsername = this.tidyEmailUsername(`${e.firstName}.${e.lastName}`)
    }

    if (e.campaignEmailAddress) {
      const username = e.campaignEmailAddress.match(/^([^@]*)@/)
      this.formData.campaignEmailUsername = username ? username[1] : undefined
    } else {
      // GVP have special rules for e-mail username
      if (e.campaign.abbreviation === 'GVP') {
        this.formData.campaignEmailUsername = this.tidyEmailUsername(e.firstName)
      } else {
        this.formData.campaignEmailUsername = this.tidyEmailUsername(`${e.firstName}.${e.lastName}`)
      }
    }
  }

  async notifyHR(e: NewEmployee): Promise<void> {
    const msgs = this.$refs.createAccountsErrorHandler as ErrorMessages
    msgs.clear()

    if (!e) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    }

    try {
      this.notifyingHR = true
      await EmployeeService.notifyEmployeeCreated(e, 'HR')
      msgs.pushInfo('OK. Meddelandet har skickats till HR.')
    } catch (err: unknown) {
      msgs.pushErr(err)
    } finally {
      this.notifyingHR = false
    }
  }

  async notifyManager(e: NewEmployee): Promise<void> {
    const msgs = this.$refs.createAccountsErrorHandler as ErrorMessages
    msgs.clear()

    if (!e) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    }

    try {
      this.notifyingManager = true
      await EmployeeService.notifyEmployeeCreated(e, 'Manager')
      msgs.pushInfo('OK. Meddelandet har skickats till chefen.')
    } catch (err: unknown) {
      msgs.pushErr(err)
    } finally {
      this.notifyingManager = false
    }
  }

  private tidyEmailUsername(username: string): string {
    return username
      .trim()
      .toLowerCase()
      .replace('å', 'a')
      .replace('ä', 'a')
      .replace('á', 'a')
      .replace('à', 'a')
      .replace('ö', 'o')
      .replace('ó', 'o')
      .replace('ò', 'o')
      .replace('é', 'e')
      .replace('è', 'e')
      .replace('ü', 'u')
      .replace('ß', 'ss')
      .replace(' ', '')
  }

  get companyEmailAddress(): string {
    return `${this.formData.companyEmailUsername}@koncepta.se`
  }

  get campaignEmailAddress(): string {
    return `${this.formData.companyEmailUsername}@${this.campaignEmailDomain}`
  }

  get campaignEmailDomain(): string {
    const cs = this.campaigns.filter((_) => _.id === this.employee?.campaign.id ?? -1)
    if (cs.length > 0) {
      const c = cs[0]
      return c.emailDomain ?? ''
    }
    return ''
  }

  sort(columnName: string): void {
    if (columnName === this.sortBy) {
      this.sortReverse = !this.sortReverse
    } else {
      this.sortBy = columnName
      this.sortReverse = false
    }

    this.newEmployees = this.newEmployees.sort((a: NewEmployee, b: NewEmployee) => {
      const modifier = this.sortReverse ? -1 : 1

      switch (this.sortBy) {
        case 'firstName':
          return a.firstName.localeCompare(b.firstName, 'sv-SE') * modifier
        case 'lastName':
          return a.lastName.localeCompare(b.lastName, 'sv-SE') * modifier
        case 'campaign':
          return a.campaign.abbreviation.localeCompare(b.campaign.abbreviation, 'sv-SE') * modifier
      }

      return 0
    })
  }
}
