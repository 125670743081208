
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormInputDateComponent',
  props: {
    id: String,
    modelValue: Date,
    readonly: Boolean,
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      model: this.modelValue?.toISOString().substring(0, 10) ?? '',
    }
  },
  watch: {
    modelValue() {
      this.model = this.modelValue?.toISOString().substring(0, 10) ?? ''
    },
    model() {
      const value = this.model

      if (!value) {
        this.$emit('update:modelValue', null)
      } else if (typeof value === 'string') {
        this.$emit('update:modelValue', new Date(value))
      }
    },
  },
  computed: {
    inputClass() {
      if (this.readonly) {
        return 'form-control-plaintext'
      } else {
        return 'form-control'
      }
    },
  },
})
