import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    placeholder: "ÅÅMMDD-NNNN",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    id: _ctx.id,
    class: _normalizeClass(_ctx.inputClass)
  }, null, 10, _hoisted_1)), [
    [_vModelText, _ctx.model]
  ])
}